<template>
  <OnboardPage 
    :content="content"
    :deal="deal"
  >  
    <form @submit.prevent="onSubmit">
      <BaseInput
        label="Minimum spend"
        type="number"
        v-model.trim="$v.minSpend.$model"
        :validation="$v.minSpend"
      />
      <BaseInput
        label="Amount rewarded"
        type="number"
        v-model.trim="$v.awardValue.$model"
        :validation="$v.awardValue"
      />
      <BaseSubmit
        label="Next"
        :disabled="$v.$invalid"
        :submitting="submitting"
      />
    </form>
    <div
     style="margin-top:20px;"
    >
      <a
        v-if="content.showBack" 
        class="is-size-7 has-text-grey"
        @click="back()"
      >
        &larr;
        back
      </a>
    </div>
  </OnboardPage>
</template>

<script>
import {AuthError, NetworkError} from "@/errors";
import { integer, minValue, maxValue, required } from 'vuelidate/lib/validators';
import OnboardPage from "@/components/OnboardPage.vue";
import BaseInput from "@/components/form/BaseInput.vue";
import BaseSubmit from "@/components/form/BaseSubmit.vue";

export default {
  components: {
    OnboardPage,
    BaseInput,
    BaseSubmit
  },
  data() {
    return {
      loading: false,
      error: null,
      submitting: false,
      minSpend: '',
      awardValue: '',
      deal: {
        min_spend: '',
        award_value: '',
        description: '',
      },
      content: {
        page: 'organization-deal-spend',
        step: 3,
        title: 'Reward details',
        subtitle: 'Select the minimum a customer must spend to earn reward',
        sideComponentText: 'Attract more customers. Only pay when a customer purchases and claims the deal.',
        nextPage: "organization-deal-description",
        showBack: true
      }
    }
  },
  validations: {
    minSpend: { required, integer, minValue: minValue(1) },
    awardValue: { required, integer, minValue: minValue(1), maxValue: maxValue(1000) }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;     
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;     
    },
    currentDeal: function() {
      return this.$store.getters.currentDeal;     
    },
  },
  mounted() {
    if (this.currentBusiness && 
        this.currentBusiness.slug && 
        this.currentDeal &&
        this.currentDeal.slug
      ) {

      if (this.currentBusiness.is_onboarded == true) {
        this.$router.push('/deals/user/my-location/100')
      } else {
        this.deal = this.currentDeal
        this.updateDollarValues()
      }  

   
    } else {
      this.$router.push("/o/organization-new")
    } 
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    updateDollarValues() {
      // convert minSpend & awardValue from cents to dollars (we only allow whole dollar values, plus need them to work with the input field)
      this.minSpend = Math.round(this.deal.min_spend / 100)
      this.awardValue = Math.round(this.deal.award_value / 100)
    },
    onSubmit() {
      this.submitting = true;
      this.$v.$touch();
      if (this.$v.$invalid) return;

      const params = {
        user_slug: this.currentUser.slug,
        org_slug: this.currentBusiness.slug,
        slug: this.currentDeal.slug,
        deal: {
          // convert minSpend & awardValue back from dollars to cents before saving
          min_spend: Math.round(this.minSpend) * 100,
          award_value: Math.round(this.awardValue) * 100,
        }
      }
      this.$store.dispatch("updateDeal", params)
        .then(() => {
          this.$router.push('/o/' + this.content.nextPage)
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            console.error(error)
            this.error = error
          }
        })
        .finally(() => {
          this.submitting = false
        })
    }
  }
}
</script>
